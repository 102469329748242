import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Stack,
  TablePagination,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import {
  addPaymentMethod,
  billingList,
  currentPlan,
  plansList,
  updateCard,
} from "../../../redux/Account/Billing/action";
import { getItem, setItem } from "../../../utility/localStorage";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import ChangeSubscriptionModal from "./ChangePlan";
import AddpaymentModel from "./AddpaymentModel";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const Billing = () => {
  const [open, setOpen] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] =
    useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [paymentMethodLoader, setPaymentMethodLoader] = useState(false);
  const shopOWnerId = getItem("apex-saas_details").shopOWnerId;
  const [billingPageLimit, setBillingPageLimit] = useState({
    page: 1,
    limit: 10,
  });
  const [modalLoader, setModalLoader] = useState(false);
  const [newPlanData, setNewPlanData] = useState({});
  const [addCardModal, setAddCardModal] = useState(false);
  const [savePaymentLoader, setSavePaymentLoader] = useState(false);
  const [addedCardResponse, setAddedCardResponse] = useState(null);
  useEffect(() => {
    fetchBillingHistory({
      page: billingPageLimit?.page,
      limit: billingPageLimit?.limit,
    });
    fetchCurrentPlan();
  }, []);
  const fetchBillingHistory = async ({ page, limit }) => {
    setModalLoader(true);
    await dispatch(billingList({ page, limit }));
    setModalLoader(false);
  };
  const stripe = useStripe();
  const elements = useElements();
  const billingHistory = useSelector((state) => state.account.billingHistory);
  const fetchCurrentPlan = async () => {
    setShowLoader(true);

    const resp = await dispatch(currentPlan());
    const localStorageData = getItem("apex-saas_details");
    console.log("resp", resp);
    if (resp) {
      let allLocalData = localStorageData;
      let data = [];
      if (resp?.isManualSubscription) {
        data = resp?.allowedPlatforms;
      } else {
        // if (resp?.subscriptionProduct?.name == "Mobile") data.push("mobile");
        // if (resp?.subscriptionProduct?.name == "Web") data.push("web");
        // if (resp?.subscriptionProduct?.name == "Combined")
        //   data = ["web", "mobile"];
        data = resp?.allowedPlatforms;
      }
      allLocalData.allowedPlatforms = data;

      setItem("apex-saas_details", allLocalData);
      window.dispatchEvent(new Event("localStorageChangePlan"));
    }
    setShowLoader(false);
  };
  const currentPlanDetails = useSelector((state) => state.account.currentPlan);
  console.log("currentPlanDetails", currentPlanDetails);

  const fetchPlans = async () => {
    setModalLoader(true);
    const resp = await dispatch(plansList({ interval: "year" }));
    if (resp) {
      setNewPlanData(resp[2]);
    }
    setOpenSubscriptionModal(true);
    // setAddCardModal(true);

    setModalLoader(false);
  };
  const newPlan = useSelector((state) => state.account.plansList);

  useEffect(() => {
    setNewPlanData({
      type:
        currentPlanDetails?.subscriptionPrice?.nameOfProduct == "Combined"
          ? "Premium"
          : currentPlanDetails?.subscriptionPrice?.nameOfProduct,
      plan: {
        _id: newPlan?.[currentPlanDetails?.subscriptionPrice?.nameOfProduct]
          ?.year?.[0]?._id,
        priceWithoutDiscount:
          newPlan?.[currentPlanDetails?.subscriptionPrice?.nameOfProduct]
            ?.year?.[0]?.priceWithoutDiscount,
        interval:
          newPlan?.[currentPlanDetails?.subscriptionPrice?.nameOfProduct]
            ?.year?.[0]?.interval,
      },
    });
  }, [newPlan]);

  const handleChangePage = (event, newPage) => {
    setBillingPageLimit({ ...billingPageLimit, page: newPage + 1 });

    fetchBillingHistory({ page: newPage + 1, limit: billingPageLimit?.limit });
  };

  const handleChangeRowsPerPage = (event) => {
    setBillingPageLimit({ limit: parseInt(event.target.value, 10), page: 1 });
    fetchBillingHistory({ page: 1, limit: parseInt(event.target.value, 10) });
  };

  //ADD PAYMENT METHODS FOR THE SHOP
  const handleCardSubmit = async (obj) => {
    setSavePaymentLoader(true);
    setPaymentMethodLoader(true);
    const cardElement = elements.getElement("card");

    stripe.createToken(cardElement).then(async (payload) => {
      if (payload?.error?.message) {
        toast.error("Please enter complete card details");
        setPaymentMethodLoader(false);
        setSavePaymentLoader(false);

        return;
      }

      if (payload?.token?.id) {
        const cardpayload = {
          cardHolder: shopOWnerId,
          token: payload?.token?.id,
        };
        setModalLoader(true);

        let resp = await dispatch(addPaymentMethod(cardpayload));

        if (resp) {
          console.log("resp", resp);
          let payload = { paymentMethodId: resp?.data?._id };
          const resp1 = await dispatch(updateCard(payload));
          if (resp1) {
            toast.success(
              `Payment method ${
                currentPlanDetails?.currentCard ? "updated" : "added"
              } successfully`
            );
            fetchCurrentPlan();
          }
          // fetchCards(userid);
          setAddCardModal(false);
        } else {
          setPaymentMethodLoader(false);
        }
      }
      setSavePaymentLoader(false);
      setModalLoader(false);
    });

    // Create a PaymentMethod
    const result = stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: "Jenny Rosen",
        },
      })
      .then(function (result) {});
  };
  //END OF CARD SUBMIT FUNC

  if (showLoader) {
    return (
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Box padding={"20px"} paddingTop={0} marginTop={"32px"}>
      <Box
        sx={{ boxShadow: 3, bgcolor: "white" }}
        padding={"14px"}
        borderRadius={"10px"}
      >
        <Grid
          container
          // spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"10px"}
          paddingBottom={"19px"}
        >
          <Grid item xs={12} sm={8} md={9} xl={8}>
            <Typography variant="h6" className="style-24-400-32-px">
              Current Plan and Payment Method
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className="btn-text-size-manage"
              variant="contained"
              color="secondary"
              onClick={() => navigate("/account-settings/billing-plans")}
            >
              SWITCH PLAN
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} padding={"10px"}>
          {" "}
          <Grid item xs={12} sm={12} md={6}>
            <Box
              border={"1px solid #0000003B"}
              borderRadius={2}
              padding={"24px"}
            >
              <Stack direction="column">
                <Typography
                  variant="h5"
                  style={{ fontWeight: 400, fontSize: "24px" }}
                >
                  {/* Mobile Only */}
                  {Array.isArray(currentPlanDetails?.allowedPlatforms) &&
                  currentPlanDetails?.allowedPlatforms?.length == 1
                    ? currentPlanDetails?.allowedPlatforms[0][0].toUpperCase() +
                      (currentPlanDetails?.allowedPlatforms[0]).slice(1) +
                      " Subscription"
                    : currentPlanDetails?.allowedPlatforms?.length > 1
                    ? "Premium Subscription"
                    : ""}
                </Typography>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 400, fontSize: "24px" }}
                >
                  {currentPlanDetails?.ifFreeTrial
                    ? "Free Trial"
                    : currentPlanDetails?.isManualSubscription
                    ? "$" +
                      currentPlanDetails?.manualSubscriptionAmount?.toLocaleString()
                    : "$" +
                      currentPlanDetails?.subscriptionPrice?.priceWithoutDiscountInDollar?.toLocaleString() +
                      "/" +
                      currentPlanDetails?.subscriptionPrice?.interval}
                </Typography>
              </Stack>
              <Stack paddingTop={"8px"}>
                <Typography variant="body">
                  {currentPlanDetails?.ifFreeTrial ||
                  currentPlanDetails?.isManualSubscription
                    ? "Ends on "
                    : "Renews on "}

                  {dayjs(currentPlanDetails?.endDate).format("MMMM D, YYYY")}
                </Typography>
              </Stack>
              <Stack
                spacing={2}
                sx={{ width: "100%", justifyContent: "start", padding: "24px" }}
              >
                {currentPlanDetails?.subscriptionProduct?.benefits?.map(
                  (benefit) => (
                    <Typography
                      variant="body"
                      fontSize={14}
                      display={"flex"}
                      alignItems={"center"}
                      columnGap={"32px"}
                    >
                      <CheckIcon /> {benefit}
                    </Typography>
                  )
                )}
              </Stack>
              <Stack
                spacing={0}
                direction={{ xs: "column", md: "row" }}
                gap={2}
                justifyContent={"start"}
              >
                {currentPlanDetails?.subscriptionPrice?.interval == "month" && (
                  <Button
                    className="btn-text-size-manage"
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={fetchPlans}
                  >
                    SWITCH TO ANNUAL BILLING
                  </Button>
                )}
                <Button
                  className="btn-text-size-manage"
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenCancelSubscriptionModal(true)}
                >
                  CANCEL SUBSCRIPTION
                </Button>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              border={"1px solid #0000003B"}
              borderRadius={2}
              padding={"24px"}
            >
              <Stack direction="column" spacing={2}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 400, fontSize: "24px" }}
                >
                  Payment Method
                </Typography>
              </Stack>
              <Stack
                spacing={1}
                sx={{
                  justifyContent: "start",
                  marginBottom: "20px",
                  marginTop: "20px",
                  marginRight: "20px",
                  border: "1px solid black",
                  borderRadius: "8px",
                  padding: "16px",
                }}
              >
                <Typography variant="body">
                  {currentPlanDetails?.currentCard?.brand}{" "}
                  {currentPlanDetails?.currentCard?.brand &&
                    currentPlanDetails?.currentCard?.last4 &&
                    "ending in"}{" "}
                  {currentPlanDetails?.currentCard?.last4}
                </Typography>
                <Typography variant="body">
                  {currentPlanDetails?.currentCard?.exp_month &&
                    currentPlanDetails?.currentCard?.exp_year &&
                    "Expires"}{" "}
                  {currentPlanDetails?.currentCard?.exp_month}
                  {currentPlanDetails?.currentCard?.exp_month &&
                    currentPlanDetails?.currentCard?.exp_year &&
                    "/"}
                  {currentPlanDetails?.currentCard?.exp_year}
                </Typography>
              </Stack>

              <Stack spacing={2} direction="row">
                <Button
                  className="btn-text-size-manage"
                  variant="contained"
                  style={{ marginRight: "10px", marginBottom: "20px" }}
                  // onClick={handleOpen}
                  onClick={() => setAddCardModal(true)}
                  disabled={currentPlanDetails?.isManualSubscription}
                >
                  {currentPlanDetails?.currentCard ? "Update" : "Add"}
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box
                    sx={{
                      ...style,
                      width: "720px",
                      maxHeight: "70%",
                      overflow: "scroll",
                    }}
                  >
                    <AppBar
                      variant="elevation"
                      color="secondary"
                      sx={{
                        height: "64px",
                        paddingRight: "0px !important",
                      }}
                    >
                      <Toolbar variant="regular">
                        <Typography
                          variant="h6"
                          gutterBottom={false}
                          width={"98%"}
                          color={"white"}
                        >
                          Payment Method
                        </Typography>
                        <Stack direction={"row"} spacing={0}>
                          <div
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                          >
                            <CloseIcon />
                          </div>
                        </Stack>
                      </Toolbar>
                    </AppBar>

                    <Container
                      gap={"24px"}
                      disableGutters
                      style={{ height: "fit-content", marginTop: "75px" }}
                    >
                      <Grid container justifyContent={"start"} spacing={3}>
                        <Grid item md={12} xl={12} spacing={2}>
                          <Typography variant="body1" className="label-500">
                            Card Number
                          </Typography>
                          <TextField
                            variant="outlined"
                            size="medium"
                            state="enabled"
                            fullWidth
                          ></TextField>
                        </Grid>
                        <Grid item container md={12} xl={12} spacing={2}>
                          <Grid item md={12} xl={6}>
                            <Typography variant="body1" className="label-500">
                              Expiration Date
                            </Typography>
                            <TextField
                              variant="outlined"
                              size="medium"
                              state="enabled"
                              fullWidth
                              placeholder="MM/YY"
                            ></TextField>
                          </Grid>
                          <Grid item md={12} xl={6}>
                            <Typography variant="body1" className="label-500">
                              CVV
                            </Typography>
                            <TextField
                              variant="outlined"
                              size="medium"
                              state="enabled"
                              /*label="CVV"*/
                              fullWidth
                            ></TextField>
                          </Grid>
                        </Grid>
                        <Grid item container md={12} xl={12} spacing={2}>
                          <Grid item md={12} xl={6}>
                            <Typography variant="body1" className="label-500">
                              First Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              size="medium"
                              state="enabled"
                              /*label="First Name"*/
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item md={12} xl={6}>
                            <Typography variant="body1" className="label-500">
                              Last Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              size="medium"
                              state="enabled"
                              /*label="Last Name"*/
                              fullWidth
                            ></TextField>
                          </Grid>
                        </Grid>
                        <Grid item md={12} xl={6} spacing={2}>
                          <Typography variant="body1" className="label-500">
                            Postal Code
                          </Typography>
                          <TextField
                            variant="outlined"
                            size="medium"
                            state="enabled"
                            /*label="Postal Code"*/
                            fullWidth
                          ></TextField>
                        </Grid>
                        <Grid item md={12} xl={12} spacing={2}>
                          <Typography variant="body1" className="label-500">
                            Province
                          </Typography>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={[]}
                            fullWidth
                            // sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField {...params} /*label="Province"*/ />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent={"end"}
                          spacing={2}
                          style={{ marginTop: "20px" }}
                        >
                          <Grid item spacing={2}>
                            <Stack spacing={2} direction="row">
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                              >
                                Cancel{" "}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {}}
                              >
                                SAVE
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Modal>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={2} borderRadius={2} sx={{ bgcolor: "white" }}>
        <Grid
          container
          spacing={2}
          style={{ marginTop: "32px", padding: "8px 24px" }}
        >
          <Grid item xs={12} sm={12} md={12} xl={12} spacing={2}>
            <Typography
              variant="h5"
              style={{ fontWeight: 400, fontSize: "24px" }}
            >
              Billing History
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} boxShadow={0} mt={1}>
            <TableContainer component={Paper} style={{ boxShadow: "none" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography fontWeight={500} fontSize={"14px"}>
                        Date{" "}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Invoice Total</TableCell>
                    <TableCell align="left">Payment Method</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(billingHistory?.data) &&
                    billingHistory?.data.map((row) => (
                      <TableRow
                        key={row.createdAt}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {dayjs(row.createdAt).format("MMM D, YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          {row?.price?.nameOfProduct == "Combined"
                            ? "Web and Mobile"
                            : row?.price?.nameOfProduct}{" "}
                          {row?.price?.interval == "year" && "(annual)"}
                          {row?.price?.interval == "month" && "(monthly)"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.price?.unitAmountInDollars
                            ? `$${row?.price?.unitAmountInDollars}`
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {row?.cardBrand == "visa" ? "Visa" : row?.cardBrand}{" "}
                          {row?.cardLast4 && "*" + row?.cardLast4}{" "}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "rgba(0, 108, 221, 1)",
                            }}
                          >
                            {" "}
                            <Button
                              size="small"
                              variant="body2"
                              onClick={() => window.open(row?.pdfUrl, "")}
                              disabled={!row?.pdfUrl}
                            >
                              VIEW INVOICE
                            </Button>
                          </Link>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Grid container item alignItems={"center"} justifyContent="end">
                <Grid item>
                  {billingHistory?.totalCount > 0 && (
                    <TablePagination
                      component="div"
                      count={billingHistory?.totalCount}
                      page={billingPageLimit.page - 1}
                      onPageChange={handleChangePage}
                      rowsPerPage={billingPageLimit.limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </Grid>
              </Grid>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
      {/* <Modal open={addCardModal} onClose={() => setAddCardModal(false)}>
        <Box
          sx={{
            ...style,
            width: "600px",
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Payment Method
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div style={{ cursor: "pointer", height: "32px" }}>
                  <CloseIcon
                    style={{ color: "#FFFFFF", fontSize: 32 }}
                    onClick={() => setAddCardModal(false)}
                  />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              scrollbarColor: "blue",
              scrollbarWidth: "2px",
              // overflowY: "scroll",
              marginTop: "75px",
              paddingRight: "14px",
            }}
          >
            <Grid container justifyContent={"start"} spacing={3}>
              <Grid item md={12} xl={12} spacing={2}>
                <CardElement />
              </Grid>

              <Grid
                item
                container
                justifyContent={"end"}
                spacing={2}
                // style={{ marginTop: "20px" }}
              >
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setAddCardModal(false)}
                    >
                      Cancel{" "}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCardSubmit}
                      disabled={savePaymentLoader}
                    >
                      {savePaymentLoader && (
                        <>
                          <CircularProgress
                            size={20}
                            sx={{ mr: 1, color: "grey" }}
                          />
                        </>
                      )}{" "}
                      SAVE
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
        
          </Container>
        </Box>
      </Modal> */}
      <AddpaymentModel
        addCardModal={addCardModal}
        setAddCardModal={setAddCardModal}
        setModalLoader={setModalLoader}
        fetchCurrentPlan={fetchCurrentPlan}
        setAddedCardResponse={setAddedCardResponse}
      />

      <ChangeSubscriptionModal
        openSubscriptionModal={openSubscriptionModal}
        setOpenSubscriptionModal={setOpenSubscriptionModal}
        currentPlan={currentPlanDetails}
        newPlan={newPlanData}
        fetchCurrentPlan={fetchCurrentPlan}
      />
      <CancelSubscriptionModal
        openCancelSubscriptionModal={openCancelSubscriptionModal}
        setOpenCancelSubscriptionModal={setOpenCancelSubscriptionModal}
      />
      <Dialog fullScreen={fullScreen} open={modalLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Billing;
