import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Alert,
  AppBar,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  debounce,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { REQUIRED_RED } from "../../App";
import {
  getCityList,
  getCountryList,
  getProvinceList,
  shopImportedCustomerCreate,
} from "../../redux/Customer/action";
import { customerDetailsBasedOnType } from "../../redux/Invoice/action";
import { CONTACT_PREFRENCES, PHONE_TYPES } from "../../utility/Constant";
import {
  customStyles,
  errorCustomStyles,
  phoneTypeCustomStyles,
} from "../../utility/SelectDropdown";
import { inputFormatPhoneNumber } from "../Common/FormatPhoneNumber";
import SuccessMessageModal from "../Common/SuccessMessage";
import { displayError } from "../../utility/utilityFunctions";
import AddressSelect from "../Customers/Profile/AddressSelect";
import { handlePreventKeyDown } from "../../utility/helpers/commonFunction";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 3,
};

function EditCustomerCustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, marginLeft: "" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ConfirmCustomersInfoModel = ({
  open,
  handleClose,
  customerData,
  id,
  vehicalDetailFunction,
  setAddCustomerInfoButton,
  setCustomerData,
  setCustomerTypeData,
  state,
  setState,
  setAddVehicleInfoButton,
  data,
  vehicleInfo,
  madeFromCustomerProfile = false,
  setCustomerConvertedId = false,
  convertedCustomerId = false,
  setOpenDelete,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    reset,
  } = useForm();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { type } = useParams;
  const [vehicleInfoOpen, setVehicleInfoOpen] = useState(false);
  const [completeAddress, setCompleteAddress] = useState({});
  const [showLoader, setShowLoader] = React.useState(false);
  const [placeId, setPlaceId] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [importCustomerId, setImportCustomerId] = React.useState("");
  const [messageType, setMessageType] = useState("");
  const [successfullyResp, setSuccessfullyResp] = useState(false);
  // const [convertedCustomerId, setCustomerConvertedId] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [addressValues, setAddressValue] = useState({
    street_number: "",
    city: "",
    province: "",
    postal: "",
    country: "",
    provinceId: "",
    cityId: "",
    streetAddress: "",
  });

  const [cityListLoader, setCityListLoader] = useState(false);
  const [provinceListLoader, setProvinceListLoader] = useState(false);
  const [phoneTypes, setPhoneTypes] = useState(PHONE_TYPES);
  const [contactPrefrences, setContactPrefrences] =
    useState(CONTACT_PREFRENCES);
  const [showSecondaryNumber, setShowSecondaryNumber] = useState(false);
  const [selectedPhoneTypes, setSelectedPhoneTypes] = useState({
    primary: "mobile",
    secondary: "mobile",
  });
  const [primaryEmailIndex, setPrimaryEmailIndex] = useState(0);
  const [showSecondaryEmail, setShowSecondaryEmail] = useState(false);

  const [citySearch, setCitySearch] = useState("");
  const [editModalTabvalue, setEditModalTabValue] = useState(0);
  const [error, setErrors] = useState({});

  const handleChangeEditCustomerTabValue = (event, newValue) => {
    setEditModalTabValue(newValue);
  };
  useEffect(() => {
    fetchCityList();
    fetchProvinceList();
    fetchCountryList();
    setValue(
      "primaryPhoneNumberType",
      selectedPhoneTypes
        ? phoneTypes.find((option) => option.key == selectedPhoneTypes?.primary)
        : ""
    );
  }, []);
  const fetchCityList = async (province, country) => {
    setCityListLoader(true);

    let resp = await dispatch(
      getCityList({ country: "64e738882fec8f2c9b79c139", province: province })
    );
    if (resp) {
      setCityList(resp);
    }
    setCityListLoader(false);
  };

  const fetchProvinceList = async (country) => {
    setProvinceListLoader(true);

    let resp = await dispatch(
      getProvinceList({ country: "64e738882fec8f2c9b79c139" })
    );
    if (resp) {
      setProvinceList(resp);
    }
    setProvinceListLoader(false);
  };
  const fetchCountryList = async () => {
    let resp = await dispatch(getCountryList());
    if (resp) {
      setCountryList(resp);
    }
  };
  const [fetchValues, setFetchValues] = useState();
  const CustomerDetails = async () => {
    //DONT RE RUN FUNCTION AFTER CUSTOMER IS CONVERTED VIA CUSTOMER PROFILE
    // if (convertedCustomerId) {
    //   return;
    // }

    setShowLoader(true);
    reset({
      firstName: "",
      lastName: "",
      company: "",
      phoneNumber: "",
    });
    const resp = await dispatch(customerDetailsBasedOnType(id));
    if (resp) {
      setFetchValues(resp);
      if (resp.primaryPhoneNumberType) {
        setValue(
          "primaryPhoneNumberType",
          resp.primaryPhoneNumberType
            ? phoneTypes.find(
                (option) => option.key == resp.primaryPhoneNumberType
              )
            : ""
        );
      } else {
        setValue(
          "primaryPhoneNumberType",
          selectedPhoneTypes
            ? phoneTypes.find(
                (option) => option.key == selectedPhoneTypes?.primary
              )
            : ""
        );
      }

      if (resp?.firstName) {
        setValue("firstName", resp?.firstName);
        clearErrors("firstName");
      } else {
        setError("firstName", {
          type: "custom",
          message: "First Name is required",
        });
      }

      if (resp && resp?.lastName) {
        setValue("lastName", resp?.lastName);
        clearErrors("lastName");
      }

      if (resp && resp?.email) {
        setValue("email", customerData?.email);
        clearErrors("email");
      }
      if (resp && resp?.company) {
        setValue("company", customerData?.company);
        clearErrors("company");
      }

      if (resp && resp?.phoneNumber) {
        setValue("phoneNumber", inputFormatPhoneNumber(resp?.phoneNumber));
        clearErrors("phoneNumber");
      } else {
        setError("phoneNumber", {
          type: "custom",
          message: "Phone Number is required",
        });
      }
      let address = {
        street_number: "",
        city: "",
        province: "",
        postal: "",
        country: "",
        cityId: "",
        provinceId: "",
        streetAddress: "",
      };
      if (resp && resp?.streetAddress) {
        // setAddressValue({...addressValues,streetAddress: resp?.streetAddress})
        setValue("streetAddress", resp?.streetAddress);
        address.streetAddress = resp?.streetAddress;
        clearErrors("streetAddress");
      }

      if (resp && resp?.city) {
        setValue("city", resp?.city?.city);
        address.city = resp?.city?.city;
        address.cityId = resp?.city?._id;

        clearErrors("city");
      }

      if (resp && resp?.province) {
        setValue("province", resp?.province?.province);
        address.province = resp?.province?.province;
        address.provinceId = resp?.province?._id;

        clearErrors("province");
      }

      if (resp && resp?.postalCode) {
        setValue("postalCode", resp?.postalCode);
        address.postalCode = resp?.postalCode;

        clearErrors("postalCode");
      }
      if (resp && resp?.country) {
        address.country = resp?.country?.country;

        // setValue("country", customerData?.country?.country);
        // clearErrors("country");
      }
      setAddressValue(address);
    }
    if (resp?.secondaryPhoneNumber) {
      setShowSecondaryNumber(true);
      setValue(
        "secondaryPhoneNumberType",
        resp.secondaryPhoneNumberType
          ? phoneTypes.find(
              (option) => option.key == resp?.secondaryPhoneNumberType
            )
          : ""
      );
      setValue(
        "secondaryPhoneNumber",
        inputFormatPhoneNumber(resp?.secondaryPhoneNumber)
      );
    }
    if (resp?.secondaryEmail) {
      setShowSecondaryEmail(true);
      setValue("secondaryEmail", resp?.secondaryEmail);
    }

    setShowLoader(false);
  };

  useEffect(() => {
    if (id && open && !convertedCustomerId) {
      CustomerDetails();
    }
    setAddressValue({
      ...addressValues,
      country: customerData?.country?.country,
      city: customerData?.city?.city,
      province: customerData?.province?.province,
      postalCode: customerData?.postalCode,
      streetAddress: customerData?.streetAddress,
      cityId: customerData?.city?._id,
      provinceId: customerData?.province?._id,
    });
    setEditModalTabValue(0);
  }, [id, open]);

  const handleCancle = () => {
    handleClose();
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("company", "");
    setValue("email", "");
    setValue("phoneNumber", "");
    setValue("city", "");
    setValue("province", "");
    setValue("postalCode", "");
    setValue("country", "");
    setValue("streetAddress", "");
    setErrors({});
  };

  const onSubmit = async (values) => {
    const { streetAddress, city, province, postalCode } = values;

    const isAnyFieldFilled = [streetAddress, city, province, postalCode].some(
      (field) => field && field.trim() !== ""
    );
    if (isAnyFieldFilled) {
      if (!streetAddress)
        setError("streetAddress", {
          type: "required",
          message: "Street address is required",
        });
      if (!city)
        setError("city", { type: "required", message: "City is required" });
      if (!province)
        setError("province", {
          type: "required",
          message: "Province is required",
        });
      if (!postalCode)
        setError("postalCode", {
          type: "required",
          message: "Postal code is required",
        });

      if (!streetAddress || !city || !province || !postalCode) {
        setEditModalTabValue(1);
        return;
      }
    } else {
      clearErrors();
    }
    let payload = {
      importShopCustomerId: id,
      // ...values,
    };

    if (values?.firstName) {
      payload.firstName = values?.firstName;
    }

    if (values?.lastName) {
      payload.lastName = values?.lastName;
    }
    if (values?.company) {
      payload.company = values?.company;
    }

    if (values?.phoneNumber) {
      let number = values?.phoneNumber;
      let withoutDashNumber = number.replace(/-/g, "");

      if (withoutDashNumber.length != 10) {
        return setError("phoneNumber", {
          type: "custom",
          message: "Phone number should be 10 digits long",
        });
      } else {
        clearErrors("phoneNumber");
      }

      payload.phoneNumber = values?.phoneNumber;
    }

    if (addressValues?.city) {
      payload.city = addressValues?.cityId;
    }

    if (addressValues?.country) {
      payload.country = "64e738882fec8f2c9b79c139";
    }

    if (addressValues?.postalCode) {
      payload.postalCode = addressValues?.postalCode;
    }
    if (addressValues?.province) {
      payload.province = addressValues?.provinceId;
    }
    if (addressValues?.streetAddress) {
      payload.streetAddress = addressValues?.streetAddress;
    }

    if (values?.contactPrefrence) {
      payload.contactPrefrence = values?.contactPrefrence?.key;
    }
    if (values?.primaryPhoneNumberType) {
      payload.primaryPhoneNumberType = values?.primaryPhoneNumberType?.key;
    }

    payload.secondaryPhoneNumberType = values?.secondaryPhoneNumberType?.key;

    payload.secondaryPhoneNumber = values?.secondaryPhoneNumber;

    if (primaryEmailIndex == 0) {
      payload.email = values?.email;
      payload.secondaryEmail = values?.secondaryEmail;
    } else {
      payload.secondaryEmail = values?.email;
      payload.email = values?.secondaryEmail;
    }
    setShowLoader(true);
    const resp = await dispatch(shopImportedCustomerCreate(payload));

    // let resp = true;

    if (resp) {
      // if(!state.shop_create){
      //   setState({shop_created:true})
      //   setCustomerTypeData(["shop_crated"])
      // }
      setSuccessfullyResp(true);
      setCustomerData(resp?.data);
      setAddCustomerInfoButton(true);
      setImportCustomerId(resp?.data?._id);
      setOpenSuccessModal(true);
      setSuccessMessage("Customer information confirmed successfully.");
      handleClose();

      if (madeFromCustomerProfile) {
        // handleClose();
        setErrors({});
        let convertedCustId = resp?.data?._id;
        setCustomerConvertedId(convertedCustId);

        navigate(`/customers/${convertedCustId}/shop_created`, {
          replace: true,
        });
      }
    }
    setShowLoader(false);
  };

  // const validatePhoneNumber = (value) => {
  //   const phoneNumber = value.replace(/\D/g, ""); // Remove non-numeric characters
  //   if (phoneNumber.length !== 10) {
  //     return "Phone Number must be 10 digits";
  //   }
  //   return true;
  // };
  if (showLoader) {
    return (
      <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  const debouncedHandleInputCityChange = debounce(handleInputCityChange, 300);
  const handleInputCityDebounced = (event, newInputValue) => {
    debouncedHandleInputCityChange(newInputValue);
  };

  async function handleInputCityChange(newInputValue) {
    setCitySearch(newInputValue);

    // setCityList([]);
    try {
      setCityListLoader(true);
      let data;
      data = await dispatch(getCityList({ search: newInputValue }));
      // data = await dispatch(
      //   CustomersListBasedOnType(newInputValue, customerTypeData, 1, limits)
      // );
      if (data) {
        // setCustomerDetails(data.data);
        setCityList(data);
        setCityListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleCityBlur = () => {
    if (citySearch != "") {
      setCitySearch("");

      handleInputCityChange(""); // Clear the search text when the Autocomplete loses focus
    }
  };

  const validate = () => {
    const newErrors = {};
    const isAnyFieldFilled =
      addressValues.streetAddress ||
      addressValues.province ||
      addressValues.city ||
      addressValues.postal;

    if (isAnyFieldFilled) {
      if (!addressValues.streetAddress)
        newErrors.streetAddress = "Street address is required";
      if (!addressValues.province) newErrors.province = "Province is required";
      if (!addressValues.city) newErrors.city = "City is required";
      if (!addressValues.postalCode)
        newErrors.postalCode = "Postal code is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          setErrors({});
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            variant="elevation"
            sx={{
              backgroundColor: "#002394",
              color: "white",
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography
                variant="body1"
                fontSize={"20px"}
                fontWeight={500}
                width={"98%"}
                color={"white"}
              >
                Confirm Customer Information
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleCancle}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#fff", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>

          <Container
            disableGutters
            // style={{ marginTop: "46px" }}
            // sx={{ maxHeight: "77vh", overflowY: "auto" }}
            style={{
              // scrollbarColor: "blue",
              // scrollbarWidth: "thin",
              // overflowY: "auto",
              marginTop: "40px",
              // maxHeight: "80vh",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                // spacing={3}
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflowY: "auto",
                  maxHeight: "78vh",
                }}
                padding={"24px 24px 0 24px"}
              >
                {editModalTabvalue == 0 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box padding={"24px 0px 0 0"}>
                      {/* <Typography
                  variant="h6"
                  fontWeight={500}
                  color={"error"}
                  fontSize={"16px"}
                  // padding={"8px"}
                  // padding={"8px"}
                >
                  Please fill out the missing fields for this customer.
                </Typography> */}

                      <Alert
                        severity="error"
                        icon={false}
                        sx={{
                          color: "#D32F2F",
                          fontWeight: 500,
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        {" "}
                        Please fill out the missing fields for this customer.
                      </Alert>
                    </Box>
                  </Grid>
                )}

                <Tabs
                  value={editModalTabvalue}
                  onChange={handleChangeEditCustomerTabValue}
                  aria-label="basic tabs example"
                  sx={{ margin: "24px  0px" }}
                >
                  <Tab
                    label="BASIC DETAILS"
                    // style={{ display: type == SHOP_IMPORTED && "none" }}
                    {...a11yProps(0)}
                  />

                  <Tab label="ADDITIONAL DETAILS" {...a11yProps(1)} />
                </Tabs>
                {/* <Typography
                variant="h6"
                fontWeight={500}
                fontSize={"20px"}
                // paddingTop={"32px"}
              >
                Basic Details
              </Typography> */}
                {/* Basic Details Section */}
                <EditCustomerCustomTabPanel value={editModalTabvalue} index={0}>
                  <Grid container spacing={3}>
                    <Grid item container xs={12} sm={12} md={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            {" "}
                            Primary Phone Number{" "}
                            <span style={{ color: REQUIRED_RED }}>*</span>
                          </Typography>
                          <div style={{ display: "flex" }}>
                            <Grid container columnGap={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.primaryPhoneNumberType}
                                >
                                  <Controller
                                    name="primaryPhoneNumberType"
                                    control={control}
                                    rules={{
                                      required: "Phone Number Type is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        placeholder=""
                                        isClearable={true}
                                        isSearchable={true}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ==
                                                  selectedPhoneTypes?.primary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            primary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.primaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={!!errors.primaryPhoneNumberType}
                                        helperText={
                                          errors.primaryPhoneNumberType
                                            ? errors.primaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.primaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.primaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Controller
                                  name="phoneNumber"
                                  control={control}
                                  rules={{
                                    required: "Phone Number is required",
                                    pattern: {
                                      value: /^\d{3}-\d{3}-\d{4}$/,
                                      message:
                                        "Phone number should be 10 digits long",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      id="outlined-basic"
                                      /*label="Phone Number"*/
                                      //  onChange={(e)=>console.log("ee",e)}
                                      variant="outlined"
                                      fullWidth
                                      // required
                                      error={!!errors.phoneNumber}
                                      helperText={
                                        errors.phoneNumber
                                          ? errors.phoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      onChange={(e) => {
                                        setValue(
                                          "phoneNumber",
                                          inputFormatPhoneNumber(e.target.value)
                                        );
                                        if (e.target.value.length == 12) {
                                          clearErrors("phoneNumber");
                                        } else {
                                          setError("phoneNumber", {
                                            message:
                                              "Phone number should be 10 digits long",
                                          });
                                        }
                                      }}
                                      onKeyDown={handlePreventKeyDown}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Stack>
                        {!showSecondaryNumber && (
                          <div
                            onClick={() => setShowSecondaryNumber(true)}
                            style={{
                              color: "#006CDD",
                              cursor: "pointer",
                              fontWeight: 500,
                              height: "36px",
                              padding: "4px 5px",
                              letterSpacing: "0.46px",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            + ADD PHONE NUMBER
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    {showSecondaryNumber && (
                      <Grid item container xs={12} sm={12} md={12} xl={12}>
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              {" "}
                              Secondary Phone Number{" "}
                              {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                            </Typography>

                            <Grid container item columnGap={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.secondaryPhoneNumberType}
                                >
                                  <Controller
                                    name="secondaryPhoneNumberType"
                                    control={control}
                                    // rules={{
                                    //   required: "Phone Number Type is required",
                                    // }}
                                    render={({ field }) => (
                                      <Select
                                        placeholder="" // defaultValue={}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ===
                                                  selectedPhoneTypes?.primary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            secondary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.secondaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={
                                          !!errors.secondaryPhoneNumberType
                                        }
                                        helperText={
                                          errors.secondaryPhoneNumberType
                                            ? errors.secondaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.secondaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.secondaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Controller
                                  name="secondaryPhoneNumber"
                                  control={control}
                                  rules={{
                                    pattern: {
                                      value: /^\d{3}-\d{3}-\d{4}$/,
                                      message:
                                        "Phone number should be 10 digits long",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      id="outlined-basic"
                                      /*label="Phone Number"*/
                                      //  onChange={(e)=>console.log("ee",e)}
                                      variant="outlined"
                                      fullWidth
                                      // required
                                      error={!!errors.secondaryPhoneNumber}
                                      helperText={
                                        errors.secondaryPhoneNumber
                                          ? errors.secondaryPhoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      onChange={(e) => {
                                        setValue(
                                          "secondaryPhoneNumber",
                                          inputFormatPhoneNumber(e.target.value)
                                        );
                                        if (
                                          e.target.value.length == 12 ||
                                          e.target.value.length == 0
                                        ) {
                                          clearErrors("secondaryPhoneNumber");
                                        } else {
                                          setError("secondaryPhoneNumber", {
                                            message:
                                              "Phone number should be 10 digits long",
                                          });
                                        }
                                      }}
                                      onKeyDown={handlePreventKeyDown}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={2} sm={2} md={1} xl={1}>
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    color="error"
                                    size="medium"
                                    onClick={() => {
                                      setShowSecondaryNumber(false);
                                      setValue("secondaryPhoneNumber", "");
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          First Name{" "}
                          <span style={{ color: REQUIRED_RED }}>*</span>
                        </Typography>

                        <Controller
                          name="firstName"
                          control={control}
                          rules={{
                            required: "First Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              // required
                              error={!!errors.firstName}
                              helperText={
                                errors.firstName ? errors.firstName.message : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Last Name
                          {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                        </Typography>

                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              // required
                              error={!!errors.lastName}
                              helperText={
                                errors.lastName ? errors.lastName.message : ""
                              }
                              // onChange={(e) => {
                              //   if (e.target.length != 0) {
                              //     setValue("lastName", e.target.value);
                              //     clearErrors("lastName");
                              //   }
                              // }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            marginBottom: "8px",
                          }}
                        >
                          Email
                          {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <Grid container item columnGap={3} rowGap={0}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={5.7}
                              lg={5.7}
                              xl={5.7}
                            >
                              <Controller
                                name="email"
                                control={control}
                                rules={{
                                  // required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    error={!!errors.email}
                                    helperText={
                                      errors.email ? errors.email.message : ""
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} xl={5}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={0 == primaryEmailIndex}
                                      onChange={(e) => {
                                        console.log("www", e.target.checked);
                                        showSecondaryEmail &&
                                          setPrimaryEmailIndex(
                                            e.target.checked ? 0 : 1
                                          );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        {!showSecondaryEmail && (
                          <div
                            onClick={() => setShowSecondaryEmail(true)}
                            style={{
                              color: "#006CDD",
                              cursor: "pointer",
                              fontWeight: 500,
                              height: "36px",
                              padding: "4px 5px",
                              letterSpacing: "0.46px",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            + ADD EMAIL
                          </div>
                        )}
                        {showSecondaryEmail && (
                          <Grid
                            container
                            item
                            columnGap={3}
                            rowGap={2}
                            marginTop={2}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={5.7}
                              lg={5.7}
                              xl={5.7}
                            >
                              <Controller
                                name="secondaryEmail"
                                control={control}
                                rules={{
                                  // required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.secondaryEmail}
                                    helperText={
                                      errors.secondaryEmail
                                        ? errors.secondaryEmail.message
                                        : ""
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={1 == primaryEmailIndex}
                                      onChange={(e) => {
                                        setPrimaryEmailIndex(
                                          e.target.checked ? 1 : 0
                                        );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                                {/* <CheckBox  >Primary</CheckBox> */}
                                <IconButton
                                  color="error"
                                  size="medium"
                                  onClick={() => {
                                    setShowSecondaryEmail(false);

                                    setPrimaryEmailIndex(0);
                                    setValue("secondaryEmail", "");
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Address Section */}
                  {/* <Typography
                  variant="h6"
                  fontSize={"20px"}
                  fontWeight={500}
                  style={{ margin: "24px 0px" }}
                >
                  Address
                </Typography> */}
                </EditCustomerCustomTabPanel>
                <EditCustomerCustomTabPanel value={editModalTabvalue} index={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Company
                        </Typography>
                        <Controller
                          name="company"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              onChange={(e) => {
                                if (e.target.length != 0) {
                                  setValue("company", e.target.value);
                                  clearErrors("company");
                                }
                              }}
                              InputLabelProps={{
                                shrink: field?.value?.length > 0,
                              }}
                              // label="Company"
                              fullWidth
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl fullWidth error={!!errors.contactPrefrence}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Contact Preferences
                          </Typography>
                          <Controller
                            name="contactPrefrence"
                            control={control}
                            // rules={{
                            //   required: "Phone Number Type is required",
                            // }}
                            render={({ field }) => (
                              <Select
                                placeholder="" // defaultValue={}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isSearchable={true}
                                // value={
                                //   selectedPhoneTypes
                                //     ? contactPrefrences.find(
                                //         (option) =>
                                //           option.key === selectedPhoneTypes?.primary
                                //       )
                                //     : ""
                                // }
                                options={
                                  contactPrefrences ? contactPrefrences : []
                                }
                                getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                                getOptionValue={(option) => option}
                                onChange={(e) => {
                                  field.onChange(e);
                                  console.log("eee", e);
                                  // setSelectedPhoneTypes({
                                  //   ...selectedPhoneTypes,
                                  //   primary: e ? e.key : "",
                                  // });
                                }}
                                styles={
                                  errors.contactPrefrence
                                    ? {
                                        ...customStyles,
                                        ...errorCustomStyles,
                                      }
                                    : {
                                        ...customStyles,
                                      }
                                }
                                error={!!errors.contactPrefrence}
                                helperText={
                                  errors.contactPrefrence
                                    ? errors.contactPrefrence.message
                                    : ""
                                }
                                {...field}
                              />
                            )}
                          />
                          {errors.contactPrefrence && (
                            <FormHelperText>
                              {errors.contactPrefrence.message}
                            </FormHelperText>
                          )}
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Street Address
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="streetAddress"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              defaultValue={addressValues?.streetAddress}
                              // required
                              error={!!errors.streetAddress}
                              helperText={
                                errors.streetAddress
                                  ? errors.streetAddress.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                setValue("streetAddress", e.target.value);
                                setAddressValue({
                                  ...addressValues,
                                  streetAddress: e.target.value,
                                });
                              }}
                            />
                          )}
                        />
                        {/* {error && error.streetAddress && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {error.streetAddress}
                          </p>
                        )} */}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          City
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>

                        <Controller
                          name="city"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={cityList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"city"}
                              loader={cityListLoader}
                              setValue={setValue}
                              error={!!errors.city}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.city && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.city?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Province
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>

                        <Controller
                          name="province"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={provinceList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"province"}
                              setCityList={setCityList}
                              fetchCityList={fetchCityList}
                              loader={provinceListLoader}
                              setValue={setValue}
                              error={!!errors.province}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.province && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.province?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Postal Code
                          {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )}
                        </Typography>
                        <Controller
                          name="postalCode"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              /*label="Postal Code"*/
                              variant="outlined"
                              fullWidth
                              defaultValue={addressValues?.postalCode}
                              // required
                              error={!!errors.postalCode}
                              helperText={
                                errors.postalCode
                                  ? errors.postalCode.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                setValue("postalCode", e.target.value);
                                setAddressValue({
                                  ...addressValues,
                                  postal: e.target.value,
                                  postalCode: e.target.value,
                                });
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </EditCustomerCustomTabPanel>
              </Grid>

              <Grid
                item
                container
                justifyContent={"space-between"}
                spacing={2}
                gap={2}
                style={{
                  marginTop: "24px",
                  overflow: "auto",
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                }}
                padding={"0px 24px 24px 24px"}
                maxHeight={"100px"}
              >
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenDelete(true)}
                      size="large"
                    >
                      DELETE
                    </Button>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCancle}
                      size="large"
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"

                      // onClick={handleVehicleInfoOpen}
                    >
                      SAVE
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      {/* <ConfirmCustomersVehicleInfoModel
        open={vehicleInfoOpen}
        handleClose={handleVehicleInfoClose}
      /> */}

      {/* {data.length == 0 && successfullyResp && !vehicleInfo && (
        <MissingVehicleInfoModal
          open={vehicleInfoOpen}
          handleClose={handleVehicleInfoClose}
          id={importCustomerId}
          vehicalDetailFunction={vehicalDetailFunction}
          setAddVehicleInfoButton={setAddVehicleInfoButton}
        />
      )} */}

      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        alertType={messageType}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ConfirmCustomersInfoModel;
