import { Add, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useTheme } from "@emotion/react";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { REQUIRED_RED } from "../../../App";
import { FormatNumberForKm } from "../../../components/FormatNumber/FormatNumber";
import { SHOP_IMPORTED } from "../../../config/dataService/constants";
import {
  customerDetail,
  importedCustomerDetail,
} from "../../../redux/Customer/action";
import { serviceSubcategoryData } from "../../../redux/Estimate/action";
import { CustomersListBasedOnType } from "../../../redux/Invoice/action";
import { vehicleDetails } from "../../../redux/Vehicles/action";
import {
  createAppointment,
  fetchShopClosingTime,
  mechanicDetailsList,
} from "../../../redux/appointment/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import AddAppointmentServiceModal from "../../modal/AddAppointmentService";
import ConfirmCustomersInfoModel from "../../modal/ConfirmCustomersInfo";
import MissingVehicleInfoModal from "../../modal/MissingVehicleInfoModal";

import advancedFormat from "dayjs/plugin/advancedFormat"; // Import advanced format plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import DropdownLoader from "../../Common/DropdownLoader";
// import { DateTime } from "luxon";
// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const styleChip = {
  color: "#002394",
  // marginLeft: "200px",
  width: "125px",
  height: "21px",
  paddingTop: "10px",
  paddingBottom: "10px",
  alignItems: "right",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "500",
};

const NewAppointment = () => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const { id, type } = useParams();
  // const shopCloseTime = getItem("apex-saas_details").shopClosingTime;

  // console.log("foo shopCloseTime", shopCloseTime);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [customerDetails, setCustomerDetails] = React.useState([]);
  const [mechanicDetail, setMechanicDetail] = React.useState("");
  const [customerData, setCustomerData] = React.useState("");
  const [customerTypeData, setCustomerTypeData] = React.useState([]);
  const [mechanicData, setMechanicData] = React.useState("");
  const [contactPreference, setContactPreference] = React.useState("");
  const [customerOnSite, setCustomerOnSite] = React.useState("");

  const [vehicleData, setVehicleData] = React.useState("");
  const [data, setData] = React.useState("");
  const [dropOffDate, setDropOffDate] = React.useState(null);

  const currentTimeFormate = dayjs().format("YYYY-MM-DDTHH:mm").split("T")[1];
  const [assigneeLoader, setAssigneeLoader] = useState(false);
  const [dropOffTime, setDropOffTime] = React.useState();

  const [pickUpDate, setPickUpDate] = React.useState(null);

  // const shopClosingTimeFormate = dayjs(shopCloseTime)
  //   .format("YYYY-MM-DDTHH:mm")
  //   .split("T")[1];
  const [pickUpTime, setPickUpTime] = React.useState(null);
  const [serviceId, setServiceId] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [addCustomerInfoButton, setAddCustomerInfoButton] =
    React.useState(false);
  const [addVehicleInfoButton, setAddVehicleInfoButton] = React.useState(false);

  const [showLoader, setShowLoader] = React.useState(false);
  const [checkChangeLoader, setCheckChangeLoader] = useState(false);
  const [showIdLoader, setShowIdLoader] = React.useState(true);
  const [vehicleLoader, setVehicleLoader] = React.useState(false);
  const [customerListLoader, setCustomerListLoader] = React.useState(false);
  const [customerSearch, setCustomerSearch] = useState("");
  const [validationDateError, setValidationDateError] = React.useState("");

  const [comparionTimeData, setComparionTimeData] = React.useState({
    dropTime: dayjs(`2023-12-12T${dropOffTime}`),
    pickTime: dayjs(`2023-12-12T${pickUpTime}`),
  });
  const [newVehicleCreatedId, setVehicleCreatedId] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleClick(event) {
    event.preventDefault();
    // console.info("You clicked a breadcrumb.");
  }
  let limit = 5000;
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [openCustomerInfo, setOpenCustomerInfo] = React.useState(false);
  const [customerCretedFrom, setCustomerCretedFrom] = React.useState("");
  const [vehicleSourceData, setVehicleSourceData] = React.useState("");
  const [servicePageData, setServicePageData] = useState({
    totalpages: "",
    currentPage: "",
  });

  const [state, setState] = React.useState({
    shop_created: true,
    shop_imported: true,
    apex_auto_app: false,
  });

  const limits = 50;

  const handleOpen = () => setOpen(true);
  const handleOpenCustomerInfo = () => setOpenCustomerInfo(true);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCustomerInfo = () => {
    setOpenCustomerInfo(false);
  };
  const [vehicleInfoOpen, setVehicleInfoOpen] = useState(false);
  const handleVehicleInfoOpen = () => {
    setVehicleInfoOpen(true);
    handleClose();
  };
  const handleVehicleInfoClose = () => {
    setVehicleInfoOpen(false);
    handleClose();
    if (vehicleSourceData) {
      setVehicleSourceData("");
      setVehicleData("");
    }
  };

  useEffect(() => {
    reset({
      noteForCustomer: "",
    });
    setData([]);
    setVehicleData("");
    setDropOffDate(null);
    setDropOffTime(currentTimeFormate);
    setPickUpDate(null);
    // setPickUpTime(null);
    setContactPreference(null);
    setCustomerOnSite(null);
    setMechanicData("");
    setServiceId([]);
  }, [window.location.pathname]);

  const customerDataDetail = async () => {
    setShowLoader(true);

    let resp;
    if (type != SHOP_IMPORTED) {
      resp = await dispatch(customerDetail(id));
    }
    if (type == SHOP_IMPORTED) {
      resp = await dispatch(importedCustomerDetail(id));
    }
    resp.fullName = resp?.firstName + " " + resp?.lastName;
    resp.refIdUsers = id;
    resp.refIdImport = id;

    setCustomerData(resp);
    setCustomerDetails([resp]);
    setShowLoader(false);
  };
  useEffect(() => {
    // setCustomerData(id);
    if (id && type) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
      customerDataDetail();
    }
    if (!id) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
    }
    servicesName();
    mechanicList();
    // if(customerTypeData.length == 0){
    //   setCustomerDetails([])
    // }
  }, [window.location.pathname]);

  useEffect(() => {
    if (customerTypeData) {
      if (!id && !type) {
        customerList(page, limits);
      }
    }
  }, [customerTypeData]);

  useEffect(() => {
    if (customerData && customerData?.createdFrom != "shop_imported") {
      vehicalDetail();
    }
    if (customerData?.createdFrom == "shop_imported") {
      setCustomerCretedFrom("shop_imported");
      setAddCustomerInfoButton(false);
      handleOpenCustomerInfo();
    }
  }, [customerData]);
  useEffect(() => {
    let hh = currentTimeFormate.split(":")[0];
    let mm = currentTimeFormate.split(":")[1];
    if (mm < 14) {
      mm = 15;
    } else if (mm < 29) {
      mm = 30;
    } else if (mm < 44) {
      mm = 45;
    } else {
      mm = "00";
      hh = +hh + 1;
    }
    setDropOffTime(hh + ":" + mm);
  }, []);
  const customerList = async (page, limits) => {
    // setCustomerDetails([]);
    try {
      if (showIdLoader && id) {
        setShowLoader(true);
        setShowIdLoader(false);
      }
      setCustomerListLoader(true);
      let data;

      if (customerTypeData.length > 0) {
        data = await dispatch(
          CustomersListBasedOnType(
            customerSearch,
            customerTypeData,
            page,
            limits
          )
          // CustomersListBasedOnType(customerSearch, customerTypeData, page)
        );
        if (data.data) {
          setCustomerDetails([...customerDetails, ...data.data]);
          setCustomerData(data.data.find((d) => d._id == id));
          setServicePageData({
            totalpages: data?.totalPages,
            currentPage: data?.currentPage,
          });
          setCustomerListLoader(false);
          setShowLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setCheckChangeLoader(false);
  };
  const mechanicList = async () => {
    setAssigneeLoader(true);
    let data = await dispatch(mechanicDetailsList());
    setMechanicDetail(data);
    setAssigneeLoader(false);
    if (data?.length == 1) {
      setMechanicData(data[0]?._id);
    }
  };

  const servicesName = async () => {
    try {
      let serviceSubCategoryData = await dispatch(
        serviceSubcategoryData(page, limit)
      );
      setFilterData(serviceSubCategoryData.data);
    } catch (error) {
      console.log(error);
    }
  };

  let filteredArray;
  filteredArray = filterData?.filter((item) =>
    serviceId.includes(item.serviceSubCategory._id)
  );
  const vehicalDetail = async (newCustomerId) => {
    // setShowLoader(true);
    setData([]);
    setVehicleLoader(true);
    let data = await dispatch(
      vehicleDetails(
        10,
        1,
        newCustomerId ? newCustomerId : customerData?.refIdUsers
      )
    );
    setVehicleLoader(false);
    if (data) {
      setData(data);
      if (data.length == 0) {
        handleVehicleInfoOpen();
      } else if (data.length == 1) {
        setVehicleData(data[0]?._id);
        if (data[0].source && data[0].source == "shop_imported") {
          setVehicleSourceData(data[0]);
          handleVehicleInfoOpen();
        }
      } else {
        handleCloseCustomerInfo();
      }
    }
    // setShowLoader(false);
  };

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && newVehicleCreatedId) {
      setVehicleData(newVehicleCreatedId);
      setVehicleCreatedId("");
    }
  }, [data]);

  const onSubmit = async (value) => {
    const openingTime = dayjs(comparionTimeData?.dropTime);
    const closingTime = dayjs(comparionTimeData?.pickTime);

    if (dayjs(dropOffDate).isSame(pickUpDate)) {
      if (closingTime?.$H < openingTime?.$H) {
        setValidationDateError(
          "Pick up time should be later than the drop off time. "
        );
        return;
      } else if (openingTime?.$H == closingTime?.$H) {
        if (closingTime?.$M < openingTime?.$M) {
          setValidationDateError(
            "Pick up time should be later than the drop off time. "
          );
          return;
        }
      }
    }

    const dropOffDateObject = new Date(dropOffDate);
    const pickUpDateObject = new Date(pickUpDate);
    const [dropHours, dropMinutes] = dropOffTime.split(":").map(Number);
    const [pickHours, pickMinutes] = pickUpTime.split(":").map(Number);

    const DropDate = new Date(
      dropOffDateObject.getFullYear(),
      dropOffDateObject.getMonth(),
      dropOffDateObject.getDate(),
      dropHours,
      dropMinutes,
      0,
      0
    ).toISOString();
    const PickDate = new Date(
      pickUpDateObject.getFullYear(),
      pickUpDateObject.getMonth(),
      pickUpDateObject.getDate(),
      pickHours,
      pickMinutes,
      0,
      0
    ).toISOString();

    setShowLoader(true);
    let submitData = {
      customer: customerData?.refIdUsers,
      vehicle: vehicleData,

      dropOffDateTime: DropDate,
      pickupDateTime: PickDate,
      services: serviceId,
      // contactPreferences: contactPreference,
      waitingOnSite: customerOnSite,
      assignee: [mechanicData],
    };
    if (contactPreference) {
      submitData.contactPreferences = contactPreference;
    }
    if (value.noteForCustomer) {
      submitData.noteForCustomer = value.noteForCustomer.trim();
    }
    try {
      const data = await dispatch(createAppointment(submitData));

      if (data) {
        // If the data is truthy (meaning the appointment was created successfully)
        setShowLoader(false);
        setOpenSuccessModal(true);
        setSuccessMessage("Appointment created successfully.");
        setValidationDateError("");

        setTimeout(() => {
          navigate("/appointment/today");
        }, 1000);
      } else {
        // If the data is falsy (handling the case where the appointment creation failed)
        setShowLoader(false);
        // Optionally, you may want to handle the error state or provide user feedback.
      }
    } catch (error) {
      // Handle any unexpected errors during the appointment creation
      console.error("Error creating appointment:", error);
      // Optionally, you may want to set an error state or provide user feedback.
    }
  };

  const handleRemoveService = (id) => {
    let newArray = serviceId.filter((itemId) => itemId !== id);
    setServiceId(newArray);
  };

  const disablePrevDates = (startDate) => {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  };
  async function handleInputChange(newInputValue) {
    setCustomerSearch(newInputValue);

    setCustomerDetails([]);
    try {
      setCustomerListLoader(true);
      let data;

      data = await dispatch(
        CustomersListBasedOnType(newInputValue, customerTypeData, 1, limits)
      );
      if (data) {
        setCustomerDetails(data.data);
        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const debouncedHandleInputChange = debounce(handleInputChange, 300);
  const handleInputDebounced = (event, newInputValue) => {
    debouncedHandleInputChange(newInputValue);
  };

  const handleCheckChange = (event) => {
    setCheckChangeLoader(true);
    // setShowLoader(true)
    setCustomerDetails([]);
    setCustomerSearch("");
    setPage(1);
    if (event.target.name && event.target.checked == true) {
      setCustomerTypeData((prevArray) => [...prevArray, event.target.name]);
    } else {
      const filterData = customerTypeData.filter(
        (value) => value !== event.target.name
      );
      setCustomerTypeData(filterData);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
    // setShowLoader(false)
  };
  const handleBlur = () => {
    if (customerSearch != "") {
      setCustomerSearch("");

      handleInputChange(""); // Clear the search text when the Autocomplete loses focus
    }
  };

  const handleScroll = (e) => {
    const listboxNode = e.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
        listboxNode.scrollHeight &&
      servicePageData.totalpages > page
    ) {
      setPage(page + 1);
      customerList(page + 1, limits);
    }
  };

  useEffect(() => {
    getShopClosingTime(dayjs().format("YYYY-MM-DDTHH:mm"));
    // if (dropOffDate) {
    //   getShopClosingTime(dropOffDate);
    // }
  }, []);

  const getShopClosingTime = async (time) => {
    let resp = await dispatch(fetchShopClosingTime({ dropOffDateTime: time }));

    const localTime = dayjs.utc(resp?.shopClosingTime).tz(resp?.shopTimeZone);

    // console.log("localTime", localTime);

    const updatedString = localTime.$H + ":" + localTime.$m;
    // console.log("updatedString", updatedString);
    // setPickUpDate(localTime.format("YYYY-MM-DD"));
    setPickUpTime(updatedString);
  };

  return (
    <>
      <Box padding={"24px"}>
        {" "}
        <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/appointment/today"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Appointments
                </Link>
                <Link
                  to="/appointment/new-appointment"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  New Appointment
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" fontWeight={500}>
              New Appointment
            </Typography>
          </Grid>
        </Grid>
        <Grid container padding={"24px"} margin={"32px 0"} boxShadow={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container item justifyItems={"space-between"}>
              <Grid container item justifyItems={"space-between"} gap={2}>
                <Grid item container columnGap={2} rowGap={1}>
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Stack spacing={0}>
                      <Typography variant="body1" className="label-500">
                        Customer <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>

                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={customerDetails ? customerDetails : []}
                        getOptionLabel={(option) => option.fullName}
                        loading={customerListLoader}
                        disabled={id || customerTypeData.length == 0}
                        value={customerData ? customerData : null}
                        onChange={(event, newValue) => {
                          setCustomerData(newValue);
                          setCustomerCretedFrom(newValue?.createdFrom);
                          if (customerData != null) {
                            setData("");
                          }
                        }}
                        loadingText={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <CircularProgress />
                          </div>
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            onChange={(e) =>
                              handleInputDebounced(e, e.target.value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                        ListboxProps={{
                          style: { maxHeight: "300px" },
                          onScroll: (event) => {
                            handleScroll(event);
                          },
                        }}
                        renderOption={(props, option) => (
                          <>
                            <Box component="li" {...props}>
                              <li
                                {...props}
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                key={option?._id}
                                onClick={() => {
                                  if (option.createdFrom == "shop_imported") {
                                    handleOpenCustomerInfo();
                                  }
                                }}
                              >
                                <div>{option.fullName}</div>
                                <div>
                                  <Chip
                                    style={{
                                      ...styleChip,
                                      display: "flex",
                                      background:
                                        option.createdFrom == "shop_created"
                                          ? "#75DBFF80"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#E1D0FD"
                                          : "#EFEFEF",
                                      color:
                                        option.createdFrom == "shop_created"
                                          ? "#000000"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#3E1C76"
                                          : "#002394",
                                    }}
                                    className="chip-text-style"
                                    label={
                                      option.createdFrom == "shop_created"
                                        ? "Shop Created"
                                        : option.createdFrom == "shop_imported"
                                        ? "Shop Imported"
                                        : "Apex Auto"
                                    }
                                  />
                                </div>
                              </li>
                            </Box>

                            <Box component="li" {...props}>
                              {props["data-option-index"] + 1 ==
                                customerDetails?.length &&
                                customerListLoader && (
                                  <li
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",

                                      display: "flex",
                                    }}
                                  >
                                    <CircularProgress />
                                  </li>
                                )}
                            </Box>
                          </>
                        )}
                      />
                    </Stack>
                  </Grid>
                  {!addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" && (
                      <Grid
                        item
                        xl={2.7}
                        lg={2.5}
                        md={2.7}
                        sm={2.7}
                        xs={2.7}
                        paddingTop={"0px"}
                        marginTop={"0px"}
                        className="width-100-per-drop-off"
                      >
                        <Button
                          style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleOpenCustomerInfo();
                          }}
                        >
                          {" "}
                          + Add Customer Info
                        </Button>
                      </Grid>
                    )}
                </Grid>
                <Grid item container columnGap={2} rowGap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_created}
                        onChange={handleCheckChange}
                        name="shop_created"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Created"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_imported}
                        onChange={handleCheckChange}
                        name="shop_imported"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Imported"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.apex_auto_app}
                        onChange={handleCheckChange}
                        name="apex_auto_app"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Apex Auto"
                  />
                </Grid>
                <Grid item container columnGap={2} rowGap={1}>
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Stack spacing={0}>
                      <Typography variant="body1" className="label-500">
                        Vehicle <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <Select
                        value={
                          Array.isArray(data) && customerData !== null
                            ? vehicleData
                            : ""
                        }
                        onChange={(event) => {
                          setVehicleData(event.target.value);
                        }}
                        displayEmpty
                      >
                        {data &&
                          data?.map((item) => {
                            if (item)
                              return (
                                <MenuItem
                                  value={item._id}
                                  onClick={() => {
                                    if (
                                      item.source &&
                                      item.source == "shop_imported"
                                    ) {
                                      setVehicleSourceData(item);
                                      handleVehicleInfoOpen();
                                    }
                                  }}
                                >
                                  {item?.vehicleYear ? item?.vehicleYear : " "}
                                  {item?.year && item?.year}{" "}
                                  {item?.vehicleMake?.name &&
                                    item?.vehicleMake?.name}{" "}
                                  {item?.vehicleModel?.name &&
                                    item?.vehicleModel?.name}{" "}
                                  {item?.vehicleModelTrim?.name &&
                                    item?.vehicleModelTrim?.name}
                                </MenuItem>
                              );
                          })}
                        {vehicleLoader && <DropdownLoader />}
                      </Select>
                    </Stack>
                  </Grid>
                  {/* {addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" && ( */}
                  {customerData &&
                    customerData?.createdFrom != "shop_imported" && (
                      <Grid
                        item
                        xl={2.7}
                        lg={2.5}
                        md={2.7}
                        sm={2.7}
                        xs={2.7}
                        paddingTop={"0px"}
                        marginTop={"0px"}
                        className="width-100-per-drop-off"
                      >
                        <Button
                          style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleVehicleInfoOpen();
                            setVehicleSourceData("");
                          }}
                        >
                          {" "}
                          + Add New Vehicle
                        </Button>
                      </Grid>
                    )}

                  {/* )} */}
                </Grid>
                <Grid
                  container
                  item
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3 }}
                >
                  <Grid
                    item
                    xl={2.7}
                    md={2.7}
                    lg={2.7}
                    sm={2.7}
                    xs={2.7}
                    paddingTop={"0px"}
                    marginTop={"0px"}
                    className="width-100-per-drop-off"
                  >
                    <Typography variant="body1" className="label-500">
                      Drop Off Date{" "}
                      <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          value={dropOffDate}
                          onChange={(data) => {
                            setDropOffDate(data);
                            getShopClosingTime(data);
                          }}
                          style={{ width: "100%" }}
                          disablePast="true"
                          slotProps={{
                            textField: {
                              required: true,
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xl={2.9} lg={2.9} md={2.9} sm={2.9} xs={2.9}>
                    <Typography
                      variant="body1"
                      className="label-500"
                      padding={"4px 0px"}
                    >
                      Drop Off Time{" "}
                      <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <TimePicker
                          paddingTop="8px"
                          // value={dropOffTime ? dayjs(`2022-04-17T15:30`) : null}
                          value={dayjs(`2022-12-12T${dropOffTime}`)}
                          onChange={(newValue) => {
                            const updatedString =
                              newValue.$H + ":" + newValue.$m;
                            setDropOffTime(updatedString);

                            const inputDate = dayjs(newValue.$d);
                            const formattedDate = dayjs("2022-04-17T23:59:00")
                              .hour(inputDate.hour())
                              .minute(inputDate.minute())
                              .format("YYYY-MM-DDTHH:mm");
                            setComparionTimeData({
                              ...comparionTimeData,
                              dropTime: formattedDate,
                            });
                          }}
                          timeSteps={{ minutes: 15 }}
                          slotProps={{
                            textField: {
                              required: true,
                            },
                          }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xl={2.7}
                    lg={2.5}
                    md={2.7}
                    sm={2.7}
                    xs={2.7}
                    paddingTop={"0px"}
                    marginTop={"0px"}
                    className="width-100-per-drop-off"
                  >
                    <Typography variant="body1" className="label-500">
                      Pick Up Date{" "}
                      <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          // label="Drop Off Date"
                          value={pickUpDate}
                          onChange={(data) => {
                            setPickUpDate(data);
                            getShopClosingTime(dayjs(data));
                          }}
                          style={{ width: "100%" }}
                          disablePast="true"
                          slotProps={{
                            textField: {
                              required: true,
                            },
                          }}
                          shouldDisableDate={disablePrevDates(dropOffDate)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xl={2.9}
                    lg={2.9}
                    md={2.9}
                    sm={2.9}
                    xs={2.9}
                    paddingTop={"0px"}
                    marginTop={"0px"}
                  >
                    <Typography
                      variant="body1"
                      className="label-500"
                      padding={"4px 0px"}
                    >
                      Pick Up Time{" "}
                      <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <TimePicker
                          name="proposeTime"
                          // label="Pick Up Time"
                          value={dayjs(`2023-12-12T${pickUpTime}`)}
                          onChange={(newValue) => {
                            const updatedString =
                              newValue.$H + ":" + newValue.$m;
                            setPickUpTime(updatedString);
                            const inputDate = dayjs(newValue.$d);
                            const formattedDate = dayjs("2022-04-17T23:59:00")
                              .hour(inputDate.hour())
                              .minute(inputDate.minute())
                              .format("YYYY-MM-DDTHH:mm");

                            setComparionTimeData({
                              ...comparionTimeData,
                              pickTime: formattedDate,
                            });
                          }}
                          timeSteps={{ minutes: 15 }}
                        />
                        <p style={{ color: "red" }}>{validationDateError}</p>
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid item container columnGap={2} rowGap={1}>
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Stack spacing={0}>
                      <Typography variant="body1" className="label-500">
                        Customer’s Contact Preference{" "}
                        {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                      </Typography>
                      <Select
                        value={contactPreference}
                        onChange={(event) => {
                          setContactPreference(event.target.value);
                        }}
                        displayEmpty
                        // required
                      >
                        <MenuItem value="phone">Phone Call</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="message">In-App Message</MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Stack spacing={0}>
                      <Typography variant="body1" className="label-500">
                        Is the Customer Waiting on Site?{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <Select
                        value={customerOnSite}
                        onChange={(event) => {
                          setCustomerOnSite(event.target.value);
                        }}
                        displayEmpty
                        required
                      >
                        <MenuItem value="wait_on_site">Wait on Site</MenuItem>
                        <MenuItem value="dropping_off_car">
                          Dropping off Car
                        </MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item container columnGap={2} rowGap={1}>
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Stack spacing={0}>
                      <Typography variant="body1" className="label-500">
                        Assign To (Calendar){" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <Select
                        value={mechanicData}
                        onChange={(event) => {
                          setMechanicData(event.target.value);
                        }}
                        displayEmpty
                        required
                      >
                        {mechanicDetail &&
                          mechanicDetail?.map((item) => {
                            return (
                              <MenuItem value={item?._id}>{item.name}</MenuItem>
                            );
                          })}
                        {assigneeLoader && (
                          <MenuItem
                            value={null}
                            disabled
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress />
                          </MenuItem>
                        )}
                        {!assigneeLoader && mechanicDetail.length == 0 && (
                          <MenuItem value={null} disabled>
                            No assignee found
                          </MenuItem>
                        )}
                      </Select>
                    </Stack>
                  </Grid>
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Stack spacing={0}>
                      <Typography variant="body1" className="label-500">
                        Add Note
                      </Typography>
                      <Controller
                        name="noteForCustomer"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              fullWidth
                              variant="outlined"
                              error={!!errors.noteForCustomer}
                              helperText={
                                errors.noteForCustomer
                                  ? errors.noteForCustomer.message
                                  : ""
                              }
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    width: "100%",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "2px",
                  }}
                />
              </Grid>

              <Grid
                item
                container
                justifyContent={"end"}
                padding={"20px 0px 10px 0px"}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Add />}
                    size="large"
                    onClick={handleOpen}
                  >
                    ADD SERVICE
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                {Array.isArray(filteredArray) &&
                  filteredArray.map((item, index) => {
                    return (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#c5d0da",
                            color: "black",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              style={{
                                padding: "16px",
                                backgroundColor: "#002394",
                                color: "white",
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                              }}
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                              fontSize={"20px"}
                              letterSpacing={"0.15px"}
                              lineHeight={"32px"}
                            >
                              {item?.serviceSubCategory?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Table
                          aria-label="caption table"
                          padding="16px"
                          style={{ boxShadow: 0 }}
                        >
                          <TableHead
                            style={{
                              backgroundColor: "#f4f6f8",
                              fontWeight: 500,
                            }}
                          >
                            <TableRow>
                              <TableCell>No.</TableCell>
                              <TableCell width={"600px"}>
                                {" "}
                                Service Name
                              </TableCell>
                              <TableCell width={"600px"}> Cost</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                width={"200px"}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell align="left">
                                {item?.serviceSubCategory?.name}
                              </TableCell>
                              <TableCell align="left">
                                {item?.priceType == "no_price"
                                  ? "-"
                                  : item?.priceType == "fixed"
                                  ? "$" + FormatNumberForKm(item?.fixedPrice)
                                  : item?.priceType == "range"
                                  ? "$" +
                                    FormatNumberForKm(item?.priceMin) +
                                    " - " +
                                    "$" +
                                    FormatNumberForKm(item?.priceMax)
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Grid
                          container
                          item
                          justifyContent={"end"}
                          padding={"24px"}
                        >
                          <Grid item>
                            <Stack direction="row" spacing={2}>
                              <Button
                                variant="contained"
                                color="error"
                                startIcon={<Delete />}
                                size="large"
                                onClick={() => {
                                  handleRemoveService(
                                    item?.serviceSubCategory?._id
                                  );
                                }}
                              >
                                REMOVE SERVICE
                              </Button>
                            </Stack>
                          </Grid>{" "}
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
            <Divider
              style={{
                width: "100%",
                borderBottomStyle: "solid",
                borderBottomWidth: "2px",
              }}
            />
            <Grid container justifyContent={"end"} padding={"20px 0px "}>
              <Grid item>
                <Stack spacing={2} direction={"row"}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="submit"
                  >
                    DONE
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <AddAppointmentServiceModal
          open={open}
          handleClose={handleClose}
          filteredArray={filteredArray}
          appointentServiceModalDataProps={(dataId, page) => {
            let serviceIdArray = serviceId;
            serviceIdArray.push(dataId);
            setServiceId(serviceIdArray);
            setPage(page);
          }}
          servicesNameAPI={servicesName}
        />
        <ConfirmCustomersInfoModel
          open={openCustomerInfo}
          handleClose={handleCloseCustomerInfo}
          customerData={customerData}
          id={customerData?.refIdImport}
          vehicalDetailFunction={vehicalDetail}
          setAddCustomerInfoButton={setAddCustomerInfoButton}
          setCustomerData={setCustomerData}
          setCustomerTypeData={setCustomerTypeData}
          state={state}
          setState={setState}
          setAddVehicleInfoButton={setAddVehicleInfoButton}
          madeFromCustomerProfile={false}
        />
        <MissingVehicleInfoModal
          open={vehicleInfoOpen}
          handleClose={handleVehicleInfoClose}
          id={customerData?.refIdUsers}
          vehicalDetailFunction={vehicalDetail}
          setAddVehicleInfoButton={setAddVehicleInfoButton}
          setVehicleCreatedId={setVehicleCreatedId}
          vehicleSource={vehicleSourceData}
          setVehicleSourceData={setVehicleSourceData}
        />
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
        />
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={checkChangeLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewAppointment;
